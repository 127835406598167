body {
    background-color: black;
    text-align: center;
}

.container {
    position: relative;
}

.img {
    display: table;
    margin: 0 auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    background-color: black;
}
